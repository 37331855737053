@font-face {
  font-family: 'IBMPlexSans';
  src: url('../fonts/IBMPlexSans-Regular.ttf');
  font-weight: normal;
  font-style: bold;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('../fonts/IBMPlexSans-Medium.ttf');
  font-weight: normal;
  font-style: initial;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('../fonts/IBMPlexSans-Regular.ttf');
  font-weight: normal;
  font-style: oblique;
}
