@import '../../assets/styles/styles.module.scss';

.title {
  text-transform: uppercase;
  font-weight: bold;
}

.description {
  color: black;
  font-size: 13px;
  text-transform: uppercase;
}

.button {
  width: 100px;
  height: 40px;
  border-radius: 0px;
  background: $orangeGradient;
  text-transform: uppercase;
  box-shadow: none;
  color: $white;
  border: 1px solid $white;
  font-size: 11px;
  font-weight: bold;

  &:hover {
    color: $red !important;
    box-shadow: none !important;
    background: $white !important;
    border: 1px solid $white !important;
  }
}

.box {
  padding: 20px;
  border: 1px solid $orange;
  background-color: $white;
  cursor: pointer;

  &:hover {
    background-color: $red;
    border: 1px solid $red;
    color: $white;

    .description {
      color: $white;
    }

    .title {
      color: $white;
    }

    .button {
      color: $white;
      box-shadow: none;
      background: $red;
      border: 1px solid $white;
    }
  }
}
