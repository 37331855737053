@import '../../../assets/styles/styles.module.scss';

.subTitle {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
}

.subDescription {
  position: relative;
  font-size: 11px;
  margin-bottom: 10px;
  color: black;
}

.button {
  color: $white;
  background: $orangeGradient;
  border: 1px solid $white;
  margin-top: 5px;
  width: 100px;
  height: 40px;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  box-shadow: none;

  &:hover {
    background: $white !important;
    color: $red !important;
    border: 1px solid $white !important;
    box-shadow: none !important;
  }
}

.cardText {
  padding: 20px;
  border: 1px solid $orange;
  background-color: $white;
  z-index: 1;

  &:hover {
    background-color: $red !important;
    border: 1px solid $red !important;
    color: $white !important;

    .subTitle,
    .subDescription {
      color: $white;
    }

    .button {
      color: $white;
      background: transparent;
    }
  }
}
