@import '../../../../assets/styles/styles.module.scss';

.jobOffer {
  position: relative;
  cursor: pointer;
  display: flex;
  height: 100px;
  width: 100%;
  border: 0.5px solid $orange;

  @media (max-width: 320px) {
    height: 150px;
  }

  .rectangleLeft {
    width: 100%;
    padding: 4px 10px 10px 10px;
    margin: auto;
  }

  .rectangleLeft::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    background: $orangeGradient;

    a:link,
    a:visited {
      color: $white;
    }

    .triangleBottomRight {
      color: black;

      &::before {
        background: $white;
      }
    }

    .titleJob,
    .subtitleJob,
    .descriptionJob {
      color: $white;
    }
  }

  .triangleBottomRight {
    position: relative;
    bottom: 0px;
    min-width: 95px;
    height: 100%;
    overflow: hidden;
    vertical-align: bottom;
    z-index: 1;
    color: $white;

    &::before {
      position: absolute;
      content: '';
      background: $orangeGradient;
      height: 100px;
      width: 179px;
      z-index: -1;
      transform: rotate(-122deg) translate(1%, -99%);
    }
  }

  .category {
    position: absolute;
    bottom: 0;
    right: 5px;
  }

  a:link,
  a:visited {
    color: initial;
  }

  .titleJob {
    font-size: 12px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    color: black;
  }

  .subtitleJob {
    font-size: 11px;
    font-weight: bold;
    color: black;
  }

  .descriptionJob {
    font-size: 10px;
    color: rgb(133, 133, 133);
  }
}
