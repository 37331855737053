@import '../../assets/styles/styles.module.scss';

.cardHeight {
  min-height: 250px;
}

.cardText {
  padding: 20px;
  border: 1px solid $orange;
  background-color: $white;
  z-index: 1;

  &:hover {
    background-color: $red !important;
    border: 1px solid $red !important;
    color: $white !important;

    .subTitle,
    .subDescription {
      color: $white;
    }

    .button {
      color: $white;
      background: transparent;
    }
  }
}

.cardTextSBS {
  padding: 20px;
  border: 1px solid $red;
  background-color: $orange;
  z-index: 1;

  &:hover {
    background-color: $white !important;
    border: 1px solid $orange !important;
    color: black !important;

    .subDescriptionSBS {
      color: black;
    }

    .subTitleSBS {
      color: $orange;
    }

    .button {
      color: $white;
      background: $red;
    }
  }
}

.subTitleSBS,
.subTitle {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
}

.subTitle {
  color: $orange;
}

.subTitleSBS {
  color: $white;
}

.subDescription,
.subDescriptionSBS {
  position: relative;
  font-size: 11px;
}

.subDescription {
  color: black;
}

.subDescriptionSBS {
  color: $white;
}

.btnSBS {
  color: $red;
  background: $white;
  border: 1px solid $orange;

  &:hover {
    background: $orangeGradient !important;
    color: $white !important;
    border: 1px solid $orange !important;
  }
}

.button {
  color: $white;
  background: $orangeGradient;
  border: 1px solid $white;

  &:hover {
    background: $white !important;
    color: $red !important;
    border: 1px solid $white !important;
  }
}

.button,
.btnSBS {
  margin-top: 2rem;
  width: 100px;
  height: 40px;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  box-shadow: none;

  &:hover {
    box-shadow: none !important;
  }
}
