$red: #ff0033;
$orange: #ff5500;
$purple: #6633cc;
$darkPurple: #000033;
$white: #ffffff;
$orangeGradient: linear-gradient(25deg, $red, $orange);
$purpleGradient: linear-gradient(25deg, $darkPurple, $purple);

:export {
  red: $red;
  orange: $orange;
  purple: $purple;
  darkPurple: $darkPurple;
  white: $white;
  orangeGradient: $orangeGradient;
  purpleGradient: $purpleGradient;
}
